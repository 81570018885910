// Change your color you want --- please input rgb color (ex: 22,33,44)

// THEME PALETTE 9 ==========================================
:root {
  --c-primary-50: 50, 168, 82;
  --c-primary-100: 50, 168, 82;
  --c-primary-200: 50, 168, 82;
  --c-primary-300: 50, 168, 82;
  --c-primary-400: 50, 168, 82;
  --c-primary-500: 50, 168, 82;
  --c-primary-600: 50, 168, 82;
  --c-primary-700: 50, 168, 82;
  --c-primary-800: 50, 168, 82;
  --c-primary-900: 50, 168, 82;

  // SECONDARY COLOR
  --c-secondary-50: 8, 0, 255;
  --c-secondary-100: 8, 0, 255;
  --c-secondary-200: 8, 0, 255;
  --c-secondary-300: 8, 0, 255;
  --c-secondary-400: 8, 0, 255;
  --c-secondary-500: 8, 0, 255;
  --c-secondary-600: 8, 0, 255;
  --c-secondary-700: 8, 0, 255;
  --c-secondary-800: 8, 0, 255;
  --c-secondary-900: 8, 0, 255;

  // NEUTRAL COLOR
  --c-neutral-50: 249, 250, 251;
  --c-neutral-100: 243, 244, 246;
  --c-neutral-200: 229, 231, 235;
  --c-neutral-300: 209, 213, 219;
  --c-neutral-400: 156, 163, 175;
  --c-neutral-500: 107, 114, 128;
  --c-neutral-600: 75, 85, 99;
  --c-neutral-700: 55, 65, 81;
  --c-neutral-800: 31, 41, 55;
  --c-neutral-900: 17, 24, 39;
}